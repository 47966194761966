var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-4"},[_vm._v(" Shared Notes & Lists ")]),_c('p',{staticClass:"tw-text-left tw-text-light-grey"},[_vm._v(" Create a note to share with the crew and inform them on important information like what to bring, how to get there, cost details, and more. ")])])],1),(_vm.dataLoaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.notesList),function(note){return _c('div',{key:note.id,staticClass:"tw-flex tw-justify-between tw-mb-8 tw-cursor-pointer"},[_c('div',{staticClass:"tw-flex tw-items-center tw-shadow-custom tw-justify-between tw-rounded-xl tw-p-4 tw-w-full",class:{
            'tw-bg-lightest-blue': note.isPinned
          },on:{"click":function($event){return _vm.$router.push({
              name: 'NoteEdit',
              params: { id: _vm.$route.params.id, noteId: note.id }
            })}}},[_c('div',{staticClass:"tw-flex tw-items-start tw-relative tw-w-full"},[(note.isPinned)?_c('img',{staticClass:"tw-cursor-pointer tw-mr-4 tw-mt-1",attrs:{"src":require("@/assets/svgs/pinned.svg"),"alt":"Pin Note"},on:{"click":function($event){$event.stopPropagation();return _vm.pinNote(note, false)}}}):_c('img',{staticClass:"tw-cursor-pointer tw-mr-4 tw-mt-1",attrs:{"src":require("@/assets/svgs/pin.svg"),"alt":"Pin Note"},on:{"click":function($event){$event.stopPropagation();return _vm.pinNote(note, true)}}}),_c('div',{staticClass:"tw-flex tw-items-center tw-flex-row tw-w-full tw-justify-between"},[_c('div',[_c('p',{staticClass:"tw-text-left tw-text-18 tw-font-semibold tw-m-0"},[_vm._v(" "+_vm._s(note.title)+" ")]),(note.isLockedBy && note.isLockedByUser)?_c('p',{staticClass:"tw-text-sm tw-text-left tw-font-medium tw-my-1 tw-m-0 tw-text-light-blue"},[_vm._v(" "+_vm._s(`${note.isLockedByUser.firstName} ${note.isLockedByUser.lastName} is currently editing`)+" ")]):_c('p',{staticClass:"tw-text-sm tw-text-left tw-font-medium tw-my-1 tw-m-0 tw-text-light-grey"},[_vm._v(" "+_vm._s(`Updated at: ${_vm.getFormattedDate(note.updatedAt)}`)+" ")])]),_c('svg',{staticClass:"tw-absolute tw-right-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"7","height":"13","viewBox":"0 0 7 13","fill":"none"}},[_c('path',{attrs:{"d":"M-4.55911e-07 1.52133L4.32659 6.11133L-6.0702e-08 10.7013L1.33198 12.1113L7 6.11133L1.33198 0.111328L-4.55911e-07 1.52133Z","fill":"#949494"}})])])])])])}),((!_vm.trip.isPremium && _vm.notesList.length === 0) || _vm.trip.isPremium)?_c('div',{staticClass:"tw-p-5 tw-flex tw-justify-between tw-items-center tw-border-dashed tw-border-2 tw-rounded tw-border-light-blue tw-cursor-pointer",on:{"click":function($event){return _vm.$router.push({
            name: 'NoteCreate',
            params: { id: _vm.$route.params.id }
          })}}},[_c('p',{staticClass:"tw-text-base tw-font-bold tw-text-light-blue tw-m-0"},[_vm._v(" Add a new Note ")]),_c('svg',{staticClass:"tw-text-light-blue tw-mr-3",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"7","height":"13","viewBox":"0 0 7 13","fill":"none"}},[_c('path',{attrs:{"d":"M-4.55911e-07 1.52133L4.32659 6.11133L-6.0702e-08 10.7013L1.33198 12.1113L7 6.11133L1.33198 0.111328L-4.55911e-07 1.52133Z","fill":"#00ACF5"}})])]):_vm._e(),(_vm.trip.isPremium)?_c('p',{staticClass:"tw-text-sm tw-text-light-grey tw-text-center tw-mt-4"},[_vm._v(" Pin to feature up to 3 notes on the Trip Hub. ")]):_vm._e(),(!_vm.trip.isPremium && _vm.notesList.length > 0)?_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center"},[_c('img',{staticClass:"tw-mt-4 tw-mb-2",attrs:{"src":require("@/assets/svgs/more-notes-icon.svg"),"alt":"Multiple Notes"}}),_c('h1',{staticClass:"tw-text-22 tw-font-semibold tw-my-2"},[_vm._v(" Create additional notes ")]),_c('p',{staticClass:"tw-text-light-grey tw-text-center"},[_vm._v(" First Class Trips get access to unlimited notes, multiple surveys, & custom invites. ")]),_c('v-btn',{staticClass:"j-btn j-premium tw-tracking-normal tw-text-white tw-mt-4",attrs:{"x-large":"","rounded":"","block":"","depressed":"","type":"button","to":{ name: 'UpgradeTripRoute' }}},[_vm._v("Upgrade to First Class")])],1):_vm._e()],2)],1):_vm._e(),_c('v-dialog',{attrs:{"content-class":"tw-rounded","max-width":"300"},model:{value:(_vm.showInfoModal),callback:function ($$v) {_vm.showInfoModal=$$v},expression:"showInfoModal"}},[_c('v-card',{staticClass:"tw-py-5 tw-px-7"},[_c('p',{staticClass:"tw-text-left tw-m-0 tw-font-semibold tw-text-light-blue"},[_vm._v(" 3 notes already pinned ")]),_c('p',{staticClass:"tw-text-left tw-text-light-grey tw-text-sm tw-m-0"},[_vm._v(" You can pin maximum of 3 notes. Please unpin one. ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }