<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3
          class="tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-4"
        >
          Shared Notes & Lists
        </h3>
        <p class="tw-text-left tw-text-light-grey">
          Create a note to share with the crew and inform them on important
          information like what to bring, how to get there, cost details, and
          more.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="dataLoaded">
      <v-col cols="12">
        <div
          class="tw-flex tw-justify-between tw-mb-8 tw-cursor-pointer"
          v-for="note in notesList"
          :key="note.id"
        >
          <div
            class="tw-flex tw-items-center tw-shadow-custom tw-justify-between tw-rounded-xl tw-p-4 tw-w-full"
            :class="{
              'tw-bg-lightest-blue': note.isPinned
            }"
            @click="
              $router.push({
                name: 'NoteEdit',
                params: { id: $route.params.id, noteId: note.id }
              })
            "
          >
            <div class="tw-flex tw-items-start tw-relative tw-w-full">
              <img
                src="@/assets/svgs/pinned.svg"
                alt="Pin Note"
                class="tw-cursor-pointer tw-mr-4 tw-mt-1"
                @click.stop="pinNote(note, false)"
                v-if="note.isPinned"
              />
              <img
                src="@/assets/svgs/pin.svg"
                alt="Pin Note"
                class="tw-cursor-pointer tw-mr-4 tw-mt-1"
                @click.stop="pinNote(note, true)"
                v-else
              />

              <div
                class="tw-flex tw-items-center tw-flex-row tw-w-full tw-justify-between"
              >
                <div>
                  <p class="tw-text-left tw-text-18 tw-font-semibold tw-m-0">
                    {{ note.title }}
                  </p>
                  <p
                    class="tw-text-sm tw-text-left tw-font-medium tw-my-1 tw-m-0 tw-text-light-blue"
                    v-if="note.isLockedBy && note.isLockedByUser"
                  >
                    {{
                      `${note.isLockedByUser.firstName} ${note.isLockedByUser.lastName} is currently editing`
                    }}
                  </p>
                  <p
                    class="tw-text-sm tw-text-left tw-font-medium tw-my-1 tw-m-0 tw-text-light-grey"
                    v-else
                  >
                    {{ `Updated at: ${getFormattedDate(note.updatedAt)}` }}
                  </p>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="13"
                  viewBox="0 0 7 13"
                  fill="none"
                  class="tw-absolute tw-right-4"
                >
                  <path
                    d="M-4.55911e-07 1.52133L4.32659 6.11133L-6.0702e-08 10.7013L1.33198 12.1113L7 6.11133L1.33198 0.111328L-4.55911e-07 1.52133Z"
                    fill="#949494"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tw-p-5 tw-flex tw-justify-between tw-items-center tw-border-dashed tw-border-2 tw-rounded tw-border-light-blue tw-cursor-pointer"
          v-if="(!trip.isPremium && notesList.length === 0) || trip.isPremium"
          @click="
            $router.push({
              name: 'NoteCreate',
              params: { id: $route.params.id }
            })
          "
        >
          <p class="tw-text-base tw-font-bold tw-text-light-blue tw-m-0">
            Add a new Note
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            class="tw-text-light-blue tw-mr-3"
          >
            <path
              d="M-4.55911e-07 1.52133L4.32659 6.11133L-6.0702e-08 10.7013L1.33198 12.1113L7 6.11133L1.33198 0.111328L-4.55911e-07 1.52133Z"
              fill="#00ACF5"
            />
          </svg>
        </div>
        <p
          class="tw-text-sm tw-text-light-grey tw-text-center tw-mt-4"
          v-if="trip.isPremium"
        >
          Pin to feature up to 3 notes on the Trip Hub.
        </p>
        <div
          class="tw-flex tw-flex-col tw-items-center"
          v-if="!trip.isPremium && notesList.length > 0"
        >
          <img
            src="@/assets/svgs/more-notes-icon.svg"
            class="tw-mt-4 tw-mb-2"
            alt="Multiple Notes"
          />
          <h1 class="tw-text-22 tw-font-semibold tw-my-2">
            Create additional notes
          </h1>
          <p class="tw-text-light-grey tw-text-center">
            First Class Trips get access to unlimited notes, multiple surveys, &amp; custom invites.
          </p>

          <v-btn
            x-large
            rounded
            block
            depressed
            type="button"
            class="j-btn j-premium tw-tracking-normal tw-text-white tw-mt-4"
            :to="{ name: 'UpgradeTripRoute' }"
            >Upgrade to First Class</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showInfoModal"
      content-class="tw-rounded"
      max-width="300"
    >
      <v-card class="tw-py-5 tw-px-7">
        <p class="tw-text-left tw-m-0 tw-font-semibold tw-text-light-blue">
          3 notes already pinned
        </p>
        <p class="tw-text-left tw-text-light-grey tw-text-sm tw-m-0">
          You can pin maximum of 3 notes. Please unpin one.
        </p>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
//import { DateTime } from "luxon";
export default {
  name: "NotesList",
  mixins: [FormattedDateRange],
  data() {
    return {
      notesList: [],
      lockedByUsers: {},
      dataLoaded: false,
      showInfoModal: false
    };
  },

  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isOwner() {
      return this.$store.state.auth.user.id === this.trip.ownerId;
    },
    isOrganizer() {
      if (
        !this.trip ||
        !this.$store.state.auth.user.trips ||
        this.$store.state.auth.user.trips.length === 0
      )
        return false;
      let currentTrip = this.$store.state.auth.user.trips.find(
        (t) => t.id === this.trip.id
      );
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    }
  },
  methods: {
    async pinNote(n, pinned) {
      if (pinned && this.notesList.filter((n) => n.isPinned).length == 3) {
        this.showInfoModal = true;
        return;
      }
      n.isPinned = pinned;
      await this.$store.dispatch("note/patch", [
        n.id,
        {
          ...n
        }
      ]);
    },
    getNoteSubtitle(note) {
      if (note.editing) return `${note.editing} is currently editing`;
      return `Updated at: ${this.getFormattedDate(note.updatedAt)}`;
    },
    async getData() {
      const notes = await this.$store.dispatch("note/find", {
        query: {
          tripId: this.$route.params.id
        }
      });

      this.notesList = notes?.data;

      if (this.notesList && this.notesList.length > 0) {
        this.notesList.sort((a, b) => {
          if (a.isPinned === b.isPinned) {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          } else {
            return a.isPinned ? -1 : 1;
          }
        });
      }

      const notesPromises = this.notesList.map((note) => {
        return note;
      });

      await Promise.all(notesPromises);
      this.dataLoaded = true;
    },
  },

  async beforeMount() {
    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Notes",
      bgColor: "#55cbfd",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    this.getData();
  }
};
</script>
<style lang="scss">
.v-icon.v-icon::after {
  background-color: transparent !important;
}
</style>
